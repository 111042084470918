import useSWR from 'swr';
import {AlertsId} from '../../entity/Alerts';
import {useFetcher} from '../useFetcher';
import {ENDPOINT_URL} from '../../config/contants';

export const useGetAlertsIdApi = (id: string) => {
    const {get} = useFetcher();
    const response = useSWR(ENDPOINT_URL + '/alerts/' + id, get);

    return {
        ...response,
        data: response.data === undefined ? undefined : (response.data as AlertsId),
    };
};
