import {DataAlertsId} from '../../../entity/Alerts';
import Loader from '../../ui/Loader';
import ButtonCustom from '../../ui/Button';
import TextField from '@mui/material/TextField';
import {useTheme} from '@mui/material';
import {useEffect, useState} from 'react';
import {useAlert} from '../../../hooks/api/useAlertIdapix';

type Props = {
  data: DataAlertsId | undefined;
};

const Comment = ({data}: Props) => {
  const theme = useTheme();

  const {updateMetadata, createMetadata} = useAlert();

  const [comment, setComment] = useState('');
  const [loadingComment, setLoadingComment] = useState(false);

  const handleSaveComment = async () => {
    if (!data) return;
    setLoadingComment(true);
    const body = {imei: data.imei, alertComment: comment, alertId: data._id};

    if (data.comment || data.tag) await updateMetadata(body, data._id);
    else await createMetadata(body, data._id);

    setLoadingComment(false);
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const lines = event.target.value.split('\n');
    if (lines.length > 6) {
      event.preventDefault();
      return;
    }
    if (!event.target.value) setComment('  ');
    else setComment(event.target.value);
  };

  useEffect(() => {
    if (!data) return;
    setComment(data.comment);
  }, [data]);

  return (
    <>
      {data ? (
        <TextField
          multiline
          fullWidth
          maxRows={6}
          placeholder="Commentaire"
          sx={{'& fieldset': {border: 'none'}, height: '100%'}}
          inputProps={{sx: {color: theme.palette.primary.light}}}
          onChange={handleTextFieldChange}
          value={comment}
        />
      ) : (
        <Loader />
      )}
      <ButtonCustom
        text={'save'}
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.light,
          height: 'auto',
          borderTop: '3px solid ' + theme.palette.primary.dark,
        }}
        onClick={() => handleSaveComment()}
        isLoading={loadingComment}
      />
    </>
  );
};

export default Comment;
