import {Grid} from '@mui/material';
import {useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import RadioButtons from '../ui/Radiobuttons';

type Props = {
  filter: string[];
  selectionChangeHandler: (event: any) => void;
};

const BatteryFilter = ({filter, selectionChangeHandler}: Props) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const tags = [
    {value: 'full', label: t('battery.full')},
    {value: 'high', label: t('battery.high')},
    {value: 'medium', label: t('battery.medium')},
    {value: 'low', label: t('battery.low')},
    {value: 'veryLow', label: t('battery.veryLow')},
    {value: 'noBattery', label: t('battery.noBattery')},
  ];

  const radioStyle = {
    color: theme.palette.primary.light,
    '&.Mui-checked': {
      color: theme.palette.primary.light,
    },
    '& .MuiSvgIcon-root': {
      width: '2vw',
    },
  };

  return (
    <Grid item xs={12} container>
      {tags.map((item, index) => (
        <Grid item key={index} color={theme.palette.primary.light}>
          <RadioButtons value={item.value} label={item.label} style={radioStyle} handleChange={selectionChangeHandler} checked={filter.includes(item.value)} />
        </Grid>
      ))}
    </Grid>
  );
};

export default BatteryFilter;
