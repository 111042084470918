import axios, {AxiosError} from 'axios';
import {useAuthenticationApi} from './api/useAuthenticationApi';
import {useAuthTokens} from './authentication/useAuthToken';

export const useFetcher = () => {
  const {tokens, addTokens, deleteTokens} = useAuthTokens();
  const {doRefreshToken} = useAuthenticationApi();
  const axiosInstance = axios.create({
    timeout: 400000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    transformRequest: function (data, headers) {
      const token = tokens;
      if (token !== undefined && headers !== undefined) {
        headers['x-access-token'] = tokens?.token;
        headers['Authorization'] = token?.token;
      }
      return JSON.stringify(data);
    },
  });

  axiosInstance.interceptors.response.use(undefined, async function (error: AxiosError) {
    if (error.response && error.response.status === 401) {
      if (tokens !== undefined && error.config?.headers !== undefined) {
        try {
          const response = await doRefreshToken(tokens.refreshToken);
          addTokens({
            token: response.token,
            refreshToken: response.refreshToken,
          });
          error.config.headers['x-access-token'] = response.token;
          error.config.headers['Authorization'] = response.token;
          return axios.request(error.config);
        } catch {}
      }
      deleteTokens();
    }
    return Promise.reject(error);
  });
  return {
    get: (url: string) => {
      return axiosInstance.get(url).then((response) => response.data);
    },
    post: (url: string, body: {}) => {
      return axiosInstance.post(url, body).then((response) => response.data);
    },
    put: (url: string, body: {}) => {
      return axiosInstance.put(url, body).then((response) => response.data);
    },
  };
};
