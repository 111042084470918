import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {CSSProperties} from 'react';

type Props = {
  value: string;
  label: string;
  style?: CSSProperties;
  checked: boolean;
  handleChange: (event: React.SyntheticEvent<Element, Event>, checked: boolean) => void;
};

const RadioButtons = ({value, label, style, handleChange, checked}: Props) => {
  return <FormControlLabel value={value} control={<Checkbox sx={style} />} label={label} onChange={handleChange} checked={checked} />;
};

export default RadioButtons;
