import React, {CSSProperties} from 'react';
import {useTheme} from '@mui/material';

type Props = {
    text: string;
    style?: CSSProperties;
};

function ErrorMessage({text, style}: Props) {
    const theme = useTheme();
    const messageStyle = {
        color: theme.palette.error.main,
        fontSize: theme.typography.button.fontSize,
        paddingTop: '2vh',
        display: 'flex',
    };

    return <span style={{...style, ...messageStyle}}>{text}</span>;
}

export default ErrorMessage;
