import {DataAlertsId} from '../../../entity/Alerts';
import {useTranslation} from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CustomTableRow from '../../ui/CustomTableRow';

type Props = {
  data: DataAlertsId;
};

const InfoClient = ({data}: Props) => {
  const {t} = useTranslation();

  const text = [
    {title: t('alerts.organisation'), text: data.organizationName},
    {title: t('infos.firstname'), text: data.firstname},
    {title: t('infos.lastname'), text: data.lastname},
    {title: t('infos.email'), text: 'email'},
    {title: t('infos.phone'), text: data.phone},
  ];

  return (
    <Table>
      <TableBody>
        {text.map((item, index) => (
          <CustomTableRow key={index} style={{textAlign: 'start'}}>
            <td style={{paddingLeft: '5%'}}>{`${item.title}: `}</td>
            <td>
              {item.text === 'email' ? (
                <a href={`mailto:${data.email}`} style={{color: 'inherit'}} target="_blank" rel="noreferrer">
                  {data.email}
                </a>
              ) : (
                item.text
              )}
            </td>
          </CustomTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default InfoClient;
