import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useTheme} from '@mui/material';

type Props = {
  filter: string[];
  names: {tag: string; label: string}[];
  selectionChangeHandler: (event: any) => void;
  title: string;
};

const Filter = ({filter, title, names, selectionChangeHandler}: Props) => {
  const theme = useTheme();

  const filterStyle = {
    '.MuiSvgIcon-root': {
      color: theme.palette.primary.light,
    },
    '.MuiFormLabel-root': {
      color: theme.palette.primary.light,
    },
    '.MuiSelect-select': {
      color: theme.palette.primary.light,
    },
    '.MuiInputBase-root': {
      border: '1px solid ' + theme.palette.primary.light,
    },
    width: '100%',
  };

  return (
    <FormControl sx={filterStyle}>
      <Select
        multiple
        value={filter}
        onChange={selectionChangeHandler}
        renderValue={(filter) => (
          <span>
            {title}: {filter.length}
          </span>
        )}>
        {names.map((name, index) => (
          <MenuItem
            key={index}
            value={name.tag}
            style={{
              backgroundColor: filter.includes(name.tag) ? theme.palette.secondary.light : theme.palette.primary.light,
            }}>
            <p>{name.label}</p>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Filter;
