import TableRow from '@mui/material/TableRow';
import React, {CSSProperties} from 'react';
import {useTheme} from '@mui/material';

type Props = {
  children?: React.ReactNode;
  style?: CSSProperties;
  onClick?: () => void;
};

const CustomTableRow = ({children, style, onClick}: Props) => {
  const theme = useTheme();

  const tableRow = {
    borderBottom: '1px solid grey',
    textAlign: 'center',
    height: '7.5vh',
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    fontSize: '1.25vw',
  };
  return (
    <TableRow onClick={onClick} sx={{...tableRow, ...style}}>
      {children}
    </TableRow>
  );
};

export default CustomTableRow;
