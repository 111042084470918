import React from 'react';
import {Navigate} from 'react-router-dom';
import {routes} from '../../config/routes';
import {useAuthTokens} from '../../hooks/authentication/useAuthToken';

interface Props {
    readonly requiredAuth: boolean;
    readonly children: React.ReactElement;
}

const AuthenticatedRoute: React.FC<Props> = (props) => {
    const {children, requiredAuth} = props;
    const {tokens} = useAuthTokens();
    if (requiredAuth) {
        return tokens ? children : <Navigate to={routes.login} replace={true} />;
    } else {
        return tokens ? <Navigate to={routes.home} /> : children;
    }
};

export default AuthenticatedRoute;
