import {useTheme} from '@mui/material';
import React, {CSSProperties} from 'react';

type Props = {
  children?: React.ReactNode;
  style?: CSSProperties;
};
const CardData = ({children, style}: Props) => {
  const theme = useTheme();

  const cardStyle = {
    color: theme.palette.primary.light,
    flexDirection: 'column' as 'column',
    fontSize: '1vw',
    borderRadius: 10,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: theme.palette.primary.main,
  };
  return <div style={{...cardStyle, ...style}}>{children}</div>;
};

export default CardData;
