import React, {CSSProperties} from 'react';
import {useTheme} from '@mui/material';
import {UseFormRegisterReturn} from 'react-hook-form';

type Props = {
    register: UseFormRegisterReturn;
    placeholder: string;
    name: string;
    error?: boolean;
    style?: CSSProperties;
    type?: string;
    required?: boolean;
    backgroundColor?: string;
    fontColor: string;
};

function Input({register, name, placeholder, error, style, type, required = false, backgroundColor, fontColor}: Props) {
    const theme = useTheme();

    const inputStyle = {
        paddingLeft: '1vw',
        height: '100%',
        borderRadius: 5,
        outline: 'none',
        border: error ? '1px solid ' + theme.palette.error.main : 'none',
        backgroundColor: backgroundColor ? backgroundColor : theme.palette.secondary.main,
        color: fontColor,
        fontSize: theme.typography.button.fontSize,
    };

    return <input {...register} name={name} placeholder={placeholder} style={{...style, ...inputStyle}} type={type} required={required} />;
}

export default Input;
