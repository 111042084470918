import {Box, Grid} from '@mui/material';
import {useTheme} from '@mui/material';
import CardData from '../ui/CardData';
import MtbfCharts from './MtbfCharts';
import Loader from '../ui/Loader';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {GlobalHistory} from '../../entity/GlobalHistory';

type Props = {
  isLoading: boolean;
  data: GlobalHistory[] | undefined;
  type: string;
};

function isNotEmpty(data: GlobalHistory[] | undefined | null): data is GlobalHistory[] {
  return data !== undefined && data !== null && data.length > 0;
}

const MtbfContainer = ({isLoading, data, type}: Props) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const titleStyle = {
    color: theme.palette.primary.light,
    fontSize: '2vw',
  };

  const handleDate = () => {
    if (!isNotEmpty(data)) return;
    if (type === 'Daily' || type === 'Yesterday') return dayjs(data[0].start).utc().local().format('DD-M-YYYY');
    return `${dayjs(data[0].stop).utc().local().format('DD-M-YYYY')} / ${dayjs(data[0].start).utc().local().format('DD-M-YYYY')}`;
  };

  return (
    <Grid item container marginTop={5} spacing={2}>
      <Grid container item xs={2.5}>
        <Grid style={titleStyle} height={'10vh'}>
          <Box display="flex" justifyContent={'center'} alignItems={'center'}>
            <div>{type}</div>
          </Box>
        </Grid>

        <Grid item xs={12} container height={'30vh'} justifyContent={'flex-end'} alignItems={'end'}>
          <Grid item xs={12}>
            <CardData style={{height: '10vh', fontSize: '1.25vw'}}>
              {!isLoading && isNotEmpty(data) ? (
                data[0].mtbf !== -1 ? (
                  `mbtf:  ${Math.round(data[0].mtbf * 100) / 100}`
                ) : (
                  `${t('mtbf.untaggedAlerts')}: ${data[0].untaggedAlerts.toString()}`
                )
              ) : (
                <Loader />
              )}
            </CardData>
          </Grid>
          <Grid item xs={12}>
            <CardData style={{height: '10vh', fontSize: '1.25vw'}}>{!isLoading && data ? handleDate() : <Loader />}</CardData>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={9.5}>
        <Grid item xs={12}>
          <CardData style={{height: '100%'}}>
            <MtbfCharts data={data} />
          </CardData>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MtbfContainer;
