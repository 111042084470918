import {DataAlertsId} from '../../../entity/Alerts';
import {useTranslation} from 'react-i18next';
import getTimeMinutesSeconds from '../../../utils/getTimeMinutesSeconds';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CustomTableRow from '../../ui/CustomTableRow';
type Props = {
  data: DataAlertsId;
};

const InfoCapteur = ({data}: Props) => {
  const {t} = useTranslation();
  const {minutes, seconds} = getTimeMinutesSeconds(data.averageTransmissionTime);

  const text = [
    {title: t('alerts.imei'), text: 'imei'},
    {title: t('alerts.firmware_version'), text: data.firmwareVersion},
    {title: t('alerts.door_type'), text: data.doorType},
    {title: t('alerts.check_in_mode'), text: data.strictMode ? (data.strictMode.set ? 'True' : 'False') : null},
    {title: t('alerts.averageTransmissionTime'), text: `${minutes} minute${minutes > 1 ? 's' : ''} - ${seconds} seconde${seconds > 1 ? 's' : ''}`},
  ];

  return (
    <Table>
      <TableBody style={{overflow: 'auto'}}>
        {text.map((item, index) => (
          <CustomTableRow key={index} style={{textAlign: 'start', height: '7vh'}}>
            <td style={{paddingLeft: '5%'}}>{`${item.title}: `}</td>
            <td>
              {item.text === 'imei' ? (
                <a style={{color: 'inherit'}} target="_blank" rel="noreferrer" href={`https://dashboard.artifeel.com/?deviceImei=${data.imei}`}>
                  {data.imei}
                </a>
              ) : (
                item.text
              )}
            </td>
          </CustomTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default InfoCapteur;
