import TableBody from '@mui/material/TableBody';
import {Alerts, Data} from '../../entity/Alerts';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import {useTranslation} from 'react-i18next';
import {TableContainer} from '@mui/material';
import Loader from '../ui/Loader';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import {CSSProperties} from 'react';
import CustomTableRow from '../ui/CustomTableRow';

type Props = {
  response: Alerts | undefined;
  isLoading: boolean;
  containerStyle?: CSSProperties;
};

const AlertsTable = ({response, isLoading, containerStyle}: Props) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <TableContainer style={containerStyle}>
      <Table>
        <TableHead>
          <CustomTableRow>
            <th>{t('alerts.imei')}</th>
            <th>{t('alerts.firmware_version')}</th>
            <th>{t('alerts.timestamp')}</th>
            <th>{t('alerts.event_id')}</th>
            <th>{t('alerts.door_type')}</th>
            <th>{t('alerts.organisation')}</th>
            <th>{t('alerts.check_in_mode')}</th>
          </CustomTableRow>
        </TableHead>
        {isLoading || response === undefined ? null : (
          <TableBody style={{overflow: 'auto'}}>
            {response.data.map((row: Data, index: number) => (
              <CustomTableRow key={index} onClick={() => navigate('/alerts/' + row._id)}>
                <td>{row.imei ? row.imei.replace('86359', '') : null}</td>
                <td>{row.firmware ? row.firmware : null}</td>
                <td>{row.timestamp ? dayjs(row.timestamp).utc().local().format('DD-M-YYYY HH:mm:ss') : null}</td>
                <td>{row.eventId ? row.eventId : null}</td>
                <td>{row.doorType ? row.doorType : null}</td>
                <td>{row.organisation ? row.organisation : null}</td>
                <td>{row.strictMode ? (row.strictMode.set ? 'True' : 'False') : null}</td>
              </CustomTableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {isLoading || response === undefined ? <Loader /> : null}
    </TableContainer>
  );
};

export default AlertsTable;
