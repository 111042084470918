import React, {CSSProperties} from 'react';
import Button from '@mui/material/Button';
import {useTheme} from '@mui/material';
import Loader from './Loader';

type Props = {
  variant?: 'text' | 'outlined' | 'contained';
  text: string;
  style?: CSSProperties;
  type?: 'button' | 'reset' | 'submit';
  onClick?: () => void;
  isLoading?: boolean;
};

function ButtonCustom({variant, text, style, type, onClick, isLoading}: Props) {
  const theme = useTheme();
  const buttonStyle = {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.dark,
    fontSize: theme.typography.button.fontSize,
    height: '100%',
    width: '100%',
    fontWeight: theme.typography.button.fontWeight,
  };

  return (
    <Button onClick={onClick} variant={variant} style={{...buttonStyle, ...style}} type={type} disabled={isLoading}>
      {isLoading ? <Loader /> : text}
    </Button>
  );
}

export default ButtonCustom;
