import {Grid} from '@mui/material';
import {DataAlertsId} from '../../../entity/Alerts';

type Props = {
  data: DataAlertsId;
};

const InfoTs = ({data}: Props) => {
  return (
    <Grid item container direction={'column'} spacing={2} fontWeight={'bold'} fontSize={'0.75vw'} padding={'1vw'}>
      {data.tsParameters
        ? Object.entries(data.tsParameters).map(([key, value], index) => (
            <Grid item key={index}>
              {key}: {value}
            </Grid>
          ))
        : null}
    </Grid>
  );
};

export default InfoTs;
