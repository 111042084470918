import {Grid} from '@mui/material';
import CardData from '../ui/CardData';
import {useGetGlobalKpi} from '../../hooks/api/useGetGlobalKpi';
import {useTheme} from '@mui/material';
import Loader from '../ui/Loader';
import {useTranslation} from 'react-i18next';
import CheckinFirmwareInfos from '../home/CheckinFirmwareInfos';
import FirmwareCharts from './FirmwareCharts';
import {useState, useEffect} from 'react';
import OrganizationTable from './OrganizationTable';

const Parc: React.FC = () => {
  const response = useGetGlobalKpi();
  const theme = useTheme();
  const {t} = useTranslation();
  const [dataCharts, setDataCharts] = useState([{key: '', 'active-checkin': 0}]);

  const cardContainer = {
    display: 'flex',
    alignItems: 'center',
  };

  const titleStyle = {
    color: theme.palette.primary.light,
    fontSize: '1vw',
  };

  const compareVersion = (v1: string, v2: string) => {
    if (typeof v1 !== 'string' || typeof v2 !== 'string') return -1;
    var v1Array = v1.split('.');
    var v2Array = v2.split('.');
    const k = Math.min(v1Array.length, v2Array.length);
    for (let i = 0; i < k; ++i) {
      const value1 = parseInt(v1Array[i], 10);
      const value2 = parseInt(v2Array[i], 10);
      if (value1 > value2) return 1;
      if (value1 < value2) return -1;
    }
    return v1.length === v2.length ? 0 : v1.length < v2.length ? 1 : -1;
  };
  useEffect(() => {
    if (!response.data) return;

    let array = [];

    for (const fw of response.data.activeCheckInFwVersion) {
      array.push({key: fw._id, 'active-checkin': fw.count});
    }

    array.sort((a, b) => compareVersion(a.key, b.key));

    setDataCharts(array);
  }, [response.data]);
  return (
    <Grid container item xs={11.25} justifyContent={'center'}>
      <Grid container direction={'column'}>
        <Grid container marginTop={5} height={'20vh'}>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={3.75} style={cardContainer} direction={'column'} alignItems={'center'}>
              <h1 style={titleStyle}>{t('home.checkin_connected')}</h1>
              <CardData style={{fontSize: '2vw'}}>{response.data ? response.data.activeCheckin : <Loader />}</CardData>
            </Grid>
            <Grid item xs={3.75} style={cardContainer} direction={'column'}>
              <h1 style={titleStyle}>{t('home.checkin_sent')} cette semaine</h1>
              <CardData style={{fontSize: '2vw'}}> {response.data ? 'NODATA' : <Loader />}</CardData>
            </Grid>
            <Grid item xs={3.75} style={cardContainer} direction={'column'}>
              <h1 style={titleStyle}>{t('home.checkin_sent')} global</h1>
              <CardData style={{fontSize: '2vw'}}> {response.data ? 'NODATA' : <Loader />}</CardData>
            </Grid>
          </Grid>
        </Grid>
        <Grid container marginTop={5} height={'50vh'}>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={3.25} style={cardContainer} direction={'column'} alignItems={'center'}>
              <h1 style={titleStyle}>{t('home.checkin_connected_firmware_version')}</h1>
              <CardData style={{height: 'auto'}}>{response.data ? <CheckinFirmwareInfos data={response.data} /> : <Loader />}</CardData>
            </Grid>
            <Grid item xs={8} style={cardContainer} direction={'column'}>
              <h1 style={titleStyle}>Graphiques</h1>
              <CardData> {response.data ? <FirmwareCharts data={dataCharts} /> : <Loader />}</CardData>
            </Grid>
          </Grid>
        </Grid>

        <Grid container marginTop={5} justifyContent={'center'}>
          <Grid container justifyContent={'center'}>
            <Grid item xs={11.25} style={cardContainer} direction={'column'}>
              <CardData style={{justifyContent: 'start'}}> {response.data ? <OrganizationTable data={response.data} /> : <Loader />}</CardData>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Parc;
