import React, {useEffect} from 'react';

interface Tokens {
    readonly token: string;
    readonly refreshToken: string;
}

interface AuthTokenContextType {
    isLoading?: boolean;
    tokens?: Tokens;
    addTokens: (tokens: Tokens) => void;
    deleteTokens: () => void;
}

let AuthTokenContext = React.createContext<AuthTokenContextType>({
    addTokens: () => {},
    deleteTokens: () => {},
});

export const AuthTokenProvider: React.FC<{
    readonly children?: React.ReactNode;
}> = (props) => {
    const {children} = props;
    let [tokens, setTokens] = React.useState<Tokens | undefined>();
    const [isLoading, setLoading] = React.useState<boolean>(true);

    useEffect(() => {
        const myToken = localStorage.getItem('token');
        const myRefreshToken = localStorage.getItem('refreshToken');
        if (myToken && myRefreshToken) setTokens({token: myToken, refreshToken: myRefreshToken});
        setLoading(false);
    }, []);

    const addTokens = (token: Tokens) => {
        localStorage.setItem('token', token.token);
        localStorage.setItem('refreshToken', token.refreshToken);
        setTokens(token);
    };

    const deleteTokens = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        setTokens(undefined);
    };

    const value = {isLoading, tokens, addTokens, deleteTokens};

    return <AuthTokenContext.Provider value={value}>{children}</AuthTokenContext.Provider>;
};

export const useAuthTokens = () => {
    return React.useContext(AuthTokenContext);
};
