import {useFetcher} from '../useFetcher';
import {ENDPOINT_URL} from '../../config/contants';
import {useSWRConfig} from 'swr';

export const usePutMtbf = () => {
  const {put} = useFetcher();
  const {mutate} = useSWRConfig();

  const updateMtbf = async (type: string, start: number, stop: number, size: number, timestamp: number) => {
    try {
      await put(ENDPOINT_URL + '/mtbf', {type, start, stop});
      await mutate(ENDPOINT_URL + `/mtbf?type=${type}&size=${size}&timestamp=${timestamp}`);
    } catch (err) {}
  };

  return {
    updateMtbf,
  };
};
