import useSWR from 'swr';
import {Battery} from '../../entity/Battery';
import {useFetcher} from '../useFetcher';
import {ENDPOINT_URL} from '../../config/contants';

export const useGetBattery = (search: string, limit: number, page: number, filter: string[]) => {
  const {get} = useFetcher();
  const response = useSWR(ENDPOINT_URL + `/global-kpi/battery?search=${search}&limit=${limit}&page=${page}&tagsFilter=${filter}`, get);

  return {
    ...response,
    data: response.data === undefined ? undefined : (response.data as Battery),
  };
};
