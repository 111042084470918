import {TopTransmissionTimeEntity} from '../../entity/Checkin';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import getTimeMinutesSeconds from '../../utils/getTimeMinutesSeconds';
import {useTranslation} from 'react-i18next';
import CustomTableRow from '../ui/CustomTableRow';

type Props = {
  data: TopTransmissionTimeEntity[];
};

const TopTransmissionTime = ({data}: Props) => {
  const {t} = useTranslation();

  const handleTime = (time: number) => {
    const {minutes, seconds} = getTimeMinutesSeconds(time);

    return <>{`${minutes} minute${minutes > 1 ? 's' : ''} - ${seconds} seconde${seconds > 1 ? 's' : ''}`}</>;
  };

  return (
    <Table>
      <TableHead>
        <CustomTableRow>
          <th>{'Imei'}</th>
          <th>{t('home.time')}</th>
          <th>{t('home.alerts')}</th>
        </CustomTableRow>
      </TableHead>
      <TableBody style={{overflow: 'auto'}}>
        {data.map((item, index) =>
          index < 10 ? (
            <CustomTableRow key={index} style={{height: '6vh'}}>
              <td>
                <a style={{color: 'inherit'}} target="_blank" rel="noreferrer" href={`https://dashboard.artifeel.com/?deviceImei=${item.imei}`}>
                  {item.imei}
                </a>
              </td>
              <td>{handleTime(item.deviceAverageTransmissionTime)}</td>
              <td>{`${item.alertsNumber}`}</td>
            </CustomTableRow>
          ) : null,
        )}
      </TableBody>
    </Table>
  );
};

export default TopTransmissionTime;
