import {createTheme} from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#415a77',
      light: '#edf2f4',
      dark: '#1b263b',
    },
    secondary: {
      main: '#e0e1dd',
      light: '#adb5bd',
    },
    error: {
      main: '#FF4C00',
    },
    info: {
      main: '#4cc9f0',
      light: '#7ae582',
      dark: '#fb8500',
    },
  },

  typography: {
    fontFamily: ['-apple-system', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    button: {
      fontWeight: 'bold',
      fontSize: '2vw',
    },
  },
});
