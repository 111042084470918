import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import CustomTableRow from '../ui/CustomTableRow';
import {Battery} from '../../entity/Battery';
import formatPercentage from '../../utils/formatPercentage';

type Props = {
  data: Battery;
};
const BatteryStatus = (props: Props) => {
  const data = props.data;
  return (
    <Table>
      <TableHead>
        <CustomTableRow style={{textAlign: 'start', height: '6vh'}}>
          <th>Status</th>
          <th>Number</th>
          <th>Percentage</th>
        </CustomTableRow>
      </TableHead>
      <TableBody style={{overflow: 'auto'}}>
        {Object.entries(data.batteryLevels).map(([key, item], index) => (
          <CustomTableRow key={index} style={{height: '5vh'}}>
            {key === 'noBattery' ? (
              <td>{'BatteryNotFound'}</td>
            ) : (
              <td>
                {key.toLocaleUpperCase()} [{formatPercentage(item.min)} - {formatPercentage(item.max)}]
              </td>
            )}
            <td>{item.value}</td>
            <td>{formatPercentage(item.value / data.globalCount, 1)}</td>
          </CustomTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default BatteryStatus;
