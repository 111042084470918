import React from 'react';
import {TablePagination, useTheme} from '@mui/material';

type Props = {
  length: number;
  page: number;
  setPage: (page: number) => void;
  limit: number;
  setLimit: (limit: number) => void;
};

const Pagination = ({length, setPage, page, setLimit, limit}: Props) => {
  const theme = useTheme();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      sx={{
        '.MuiTablePagination-toolbar': {
          color: theme.palette.secondary.main,
        },
        '.MuiSvgIcon-root': {
          color: theme.palette.secondary.main,
        },
        overflow: 'none',
      }}
      component="div"
      count={!length || length <= 0 ? 0 : length}
      page={!length || length <= 0 ? 0 : page}
      onPageChange={handleChangePage}
      rowsPerPage={limit}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default Pagination;
