import {DataAlertsId} from '../../../entity/Alerts';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import getTimeMinutesSeconds from '../../../utils/getTimeMinutesSeconds';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CustomTableRow from '../../ui/CustomTableRow';
type Props = {
  data: DataAlertsId;
};

const InfoAlert = ({data}: Props) => {
  const {t} = useTranslation();

  const {minutes, seconds} = getTimeMinutesSeconds(data.alertTransmissionTime);

  const text = [
    {title: t('alerts.event_id'), text: data.eventId + '  /  ' + t('alerts.eventType.' + data.eventId)},
    {title: `${t('alerts.timestamp')}`, text: `${data.timestamp ? dayjs(data.timestamp).utc().local().format('DD-M-YYYY HH:mm:ss') : null}`},
    {title: `${t('alerts.transmissionTime')}`, text: `${minutes} minute${minutes > 1 ? 's' : ''} - ${seconds} seconde${seconds > 1 ? 's' : ''}`},
  ];

  return (
    <Table>
      <TableBody style={{overflow: 'auto'}}>
        {text.map((item, index) => (
          <CustomTableRow key={index} style={{textAlign: 'start', height: '7vh'}}>
            <td style={{paddingLeft: '5%'}}>{`${item.title}: `}</td>
            <td>{`${item.text}`}</td>
          </CustomTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default InfoAlert;
