import CardData from '../ui/CardData';
import {Box, Grid} from '@mui/material';
import {useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import {useGetBattery} from '../../hooks/api/useGetBattery';
import Input from '../ui/Input';
import {useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import BatteryTable from './BatteryTable';
import Pagination from '../ui/Pagination';
import BatteryStatus from './BatteryStatus';
import Loader from '../ui/Loader';
import BatteryFilter from './BatteryFilter';

type Search = {
  search: string;
};

const Battery = () => {
  const theme = useTheme();
  const {t} = useTranslation();

  const {register, handleSubmit} = useForm<Search>();
  const [queryParameters, setSearchParams] = useSearchParams();

  let result = queryParameters.get('search');
  const parseSearch = result ? result : '';
  const [search, setSearch] = useState(parseSearch);

  result = queryParameters.get('page');
  const parsePage = result ? parseInt(result) : 0;
  const [page, setPage] = useState(parsePage);

  result = queryParameters.get('limit');
  const parseLimit = result ? parseInt(result) : 100;
  const [limit, setLimit] = useState(parseLimit);

  result = queryParameters.get('tagsFilter');
  const parseTagsFilter = result ? result.split(',') : ['low'];
  const [filter, setFilter] = useState<string[]>(parseTagsFilter);

  const onSubmit = async (data: Search) => {
    setSearch(data.search);
    setPage(0);
    setLimit(100);
  };

  const selectionChangeTagsFilter = (event: any) => {
    setPage(0);
    setLimit(100);

    if (filter.includes(event.target.value)) setFilter((prevArray) => prevArray.filter((item) => item !== event.target.value));
    else setFilter((prevArray) => [...prevArray, event.target.value]);
  };

  const response = useGetBattery(search, limit, page, filter);

  useEffect(() => {
    setSearchParams({page: page.toString(), limit: limit.toString(), tagsFilter: filter.join(','), search: search});
  }, [page, limit, filter, search, setSearchParams]);

  return (
    <div>
      <Grid container item xs={12} sx={{alignItems: 'flex-start'}}>
        <Grid container item xs={12} marginTop={2.5} sx={{justifyContent: 'center'}}>
          <Grid item xs={6}>
            <form onSubmit={handleSubmit(onSubmit)} style={{height: '5vh'}}>
              <Input
                register={{...register('search')}}
                name={'search'}
                placeholder={t('alerts.searchBar')}
                style={{width: '97%', height: '100%'}}
                type={'text'}
                fontColor={theme.palette.primary.dark}
              />
            </form>
          </Grid>
          <Grid item xs={6}>
            <Pagination length={response.data ? response.data.length : 0} setPage={setPage} page={page} setLimit={setLimit} limit={limit} />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <BatteryFilter filter={filter} selectionChangeHandler={selectionChangeTagsFilter} />
        </Grid>
        <Grid container item xs={12} spacing={2}>
          {response.data ? (
            <>
              <Grid item xs={6}>
                <CardData style={{height: 'auto'}}>
                  <BatteryTable data={response.data} />
                </CardData>
              </Grid>
              <Grid item xs={6}>
                <CardData style={{height: 'auto'}}>
                  <BatteryStatus data={response.data} />
                </CardData>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Loader />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Battery;
