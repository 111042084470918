import {useTheme} from '@mui/material';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
import {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import Loader from '../ui/Loader';
import {Checkin} from '../../entity/Checkin';

type Props = {
  data: Checkin;
};

const CheckinCharts = ({data}: Props) => {
  const theme = useTheme();
  const [isLoading, setLoading] = useState<Boolean>(true);
  const [newData, setnewData] = useState();
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    if (!data) return;

    let copiedArray = JSON.parse(JSON.stringify(data.activeCheckinHistory));
    copiedArray = copiedArray.slice(1, 9);

    for (const data of copiedArray) {
      data.date = dayjs(data.date).format('DD-MM-YYYY');
      data.activeCheckin = Math.round((data.activeCheckin / 7) * 100) / 100;
    }
    copiedArray = copiedArray.reverse();
    let maxValue = Math.max.apply(
      null,
      copiedArray.map(function (o: {activeCheckin: number; date: string}) {
        return o.activeCheckin;
      }),
    );
    setMaxValue(maxValue * 2);
    setnewData(copiedArray);
    setLoading(false);
  }, [data]);

  return (
    <ResponsiveContainer height={'95%'} width={'95%'}>
      {!isLoading ? (
        <BarChart
          data={newData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          barSize={40}>
          <CartesianGrid strokeDasharray="3 3" opacity={0.5} />
          <XAxis stroke={theme.palette.primary.light} dataKey="date" dy={10} />
          <YAxis type="number" domain={[0, Math.round(maxValue / 100) * 100]} stroke={theme.palette.primary.light} dx={-10} />
          <Bar dataKey="activeCheckin" fill={theme.palette.primary.light} />
          <Tooltip cursor={{fill: 'transparent'}} itemStyle={{color: theme.palette.primary.main}} />
        </BarChart>
      ) : (
        <Loader />
      )}
    </ResponsiveContainer>
  );
};

export default CheckinCharts;
