import React from 'react';
import {useAuthenticationApi} from '../../hooks/api/useAuthenticationApi';
import {useAuthTokens} from '../../hooks/authentication/useAuthToken';
import logo from '../../LogoCheckIn.png';
import {useForm} from 'react-hook-form';
import {useState} from 'react';
import {useTheme} from '@mui/material';
import Card from '../ui/Card';
import Input from '../ui/Input';
import ButtonCustom from '../ui/Button';
import ErrorMessage from '../ui/ErrorMessage';
import Background from '../ui/Background';
import {Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Loader from '../ui/Loader';
import {useNavigate} from 'react-router-dom';

type Item = {
    email: string;
    password: string;
};

const Login: React.FC = () => {
    const {addTokens} = useAuthTokens();
    const {signIn} = useAuthenticationApi();
    const theme = useTheme();
    const navigate = useNavigate();

    const [loginError, setLoginError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const login = async (data: Item) => {
        setLoading(true);
        try {
            const response = await signIn(data.email, data.password);
            addTokens({
                token: response.token,
                refreshToken: response.refreshToken,
            });
            navigate('/');
        } catch (error) {
            setLoading(false);
            setLoginError(true);
        }
    };

    const {register, handleSubmit} = useForm<Item>();
    const onSubmit = (data: Item) => login(data);

    const {t} = useTranslation();

    return (
        <Background color={theme.palette.primary.main}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={11} md={9} lg={7} xl={6}>
                    <Card style={{height: '80vh'}}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container justifyContent={'center'}>
                                <Grid item xs={8} sm={6} md={5} lg={5} xl={5} paddingTop={'5vh'}>
                                    <img src={logo} alt="logo" style={{width: '100%'}}></img>
                                </Grid>
                                <Grid item xs={11} sm={10} md={9} xl={8} height="10vh" marginTop={'10vh'}>
                                    <Input
                                        register={{...register('email')}}
                                        name={'email'}
                                        placeholder={t('login.email')}
                                        style={{width: '100%'}}
                                        type={'text'}
                                        required={true}
                                        error={loginError}
                                        backgroundColor={theme.palette.primary.dark}
                                        fontColor={theme.palette.primary.light}
                                    />
                                </Grid>
                                <Grid item xs={11} sm={10} md={9} xl={8} height="10vh" marginTop={'2vh'}>
                                    <Input
                                        register={{...register('password')}}
                                        name={'password'}
                                        placeholder={t('login.password')}
                                        style={{width: '100%'}}
                                        type={'password'}
                                        required={true}
                                        error={loginError}
                                        backgroundColor={theme.palette.primary.dark}
                                        fontColor={theme.palette.primary.light}
                                    />
                                </Grid>

                                <Grid item xs={11} sm={10} md={9} xl={8} height="10vh" marginTop={'5vh'}>
                                    <ButtonCustom variant={'contained'} type={'submit'} text={t('login.loginButton')} />
                                </Grid>
                                <Grid item xs={11} sm={10} md={9} xl={8} height="10vh" marginTop={'5vh'} justifyContent={'center'} display={'flex'}>
                                    {loginError ? <ErrorMessage text={t('login.error')} /> : isLoading ? <Loader /> : null}
                                </Grid>
                            </Grid>
                        </form>
                    </Card>
                </Grid>
            </Grid>
        </Background>
    );
};

export default Login;
