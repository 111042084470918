import useSWR from 'swr';
import {HealthServicesData} from '../../entity/HealthServices';
import {useFetcher} from '../useFetcher';
import {ENDPOINT_URL} from '../../config/contants';

export const useGetHealthServices = () => {
  const {get} = useFetcher();
  const response = useSWR(ENDPOINT_URL + '/healthServices', get);

  return {
    ...response,
    data: response.data === undefined ? undefined : (response.data as HealthServicesData),
  };
};
