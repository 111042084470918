import useSWR from 'swr';
import {GlobalHistory} from '../../entity/GlobalHistory';
import {useFetcher} from '../useFetcher';
import {ENDPOINT_URL} from '../../config/contants';

export const useGetMtbf = (type: string, size: number, timestamp: number) => {
  const {get} = useFetcher();
  const response = useSWR(ENDPOINT_URL + `/mtbf?type=${type}&size=${size}&timestamp=${timestamp}`, get);

  return {
    ...response,
    data: response.data === undefined ? undefined : (response.data as GlobalHistory[]),
  };
};
