import React from 'react';
import {Grid} from '@mui/material';
import Input from '../ui/Input';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Pagination from '../ui/Pagination';
import {useState, useEffect} from 'react';
import {Item} from '../../entity/Alerts';
import AlertsTable from './AlertsTable';
import {useGetAlertsApi} from '../../hooks/api/useGetAlertsApi';
import {useTheme} from '@mui/material';
import {useSearchParams} from 'react-router-dom';
import Filter from '../ui/Filter';

const gridStyle = {
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '10vh',
};

const Alertes: React.FC = () => {
  const {register, handleSubmit} = useForm<Item>();
  const {t} = useTranslation();
  const [queryParameters, setSearchParams] = useSearchParams();

  let result = queryParameters.get('search');
  const parseSearch = result ? result : '';
  const [search, setSearch] = useState(parseSearch);

  result = queryParameters.get('page');
  const parsePage = result ? parseInt(result) : 0;
  const [page, setPage] = useState(parsePage);

  result = queryParameters.get('limit');
  const parseLimit = result ? parseInt(result) : 10;
  const [limit, setLimit] = useState(parseLimit);

  result = queryParameters.get('groupsFilter');
  const parseGroupsFilter = result ? result.split(',') : [];
  const [group, setGroup] = useState<string[]>(parseGroupsFilter);

  result = queryParameters.get('tagsFilter');
  const parseTagsFilter = result ? result.split(',') : ['untagged'];
  const [filter, setFilter] = useState<string[]>(parseTagsFilter);

  const [length, setLength] = useState(0);
  const theme = useTheme();

  const onSubmit = async (data: Item) => {
    setSearch(data.search);
    setPage(0);
    setLimit(10);
  };

  const names = [
    {tag: 'alert', label: t('alerts.tags.alert')},
    {tag: 'fake-alert', label: t('alerts.tags.fake-alert')},
    {tag: 'bad-usage', label: t('alerts.tags.bad-usage')},
    {tag: 'works', label: t('alerts.tags.works')},
    {tag: 'test', label: t('alerts.tags.test')},
    {tag: 'untagged', label: t('alerts.tags.untagged')},
    {tag: 'pending', label: t('alerts.tags.pending')},
  ];

  const groups = [{tag: 'Monitoring', label: 'Monitoring'}];

  const selectionChangeTagsFilter = (event: any) => {
    setPage(0);
    setLimit(10);

    if (event.target.value.length === 0) {
      setFilter(['untagged']);
    } else {
      setFilter(event.target.value);
    }
  };

  const selectionChangeGroupsFilter = (event: any) => {
    setPage(0);
    setLimit(10);

    setGroup(event.target.value);
  };

  useEffect(() => {
    setSearchParams({page: page.toString(), limit: limit.toString(), tagsFilter: filter.join(','), search: search, groupsFilter: group.join(',')});
  }, [page, limit, filter, search, group, setSearchParams]);

  const response = useGetAlertsApi(search, limit, page, filter, group);
  if (response.error && response.error.response?.status === 401) return <div>INVALID ACCESS</div>;
  if (response.error && response.error.response?.status !== 401) return <div>failed to load</div>;
  if (response.data && response.data.length !== length) setLength(response.data.length);

  return (
    <Grid container item xs={11.25} justifyContent={'center'} display={'flex'}>
      <Grid container item xs={11.5}>
        <Grid container direction="row" alignItems={'center'}>
          <Grid item xs={6} sx={gridStyle}>
            <form onSubmit={handleSubmit(onSubmit)} style={{height: '50%'}}>
              <Input
                register={{...register('search')}}
                name={'search'}
                placeholder={t('alerts.searchBar')}
                style={{width: '97%', height: '100%'}}
                type={'text'}
                fontColor={theme.palette.primary.dark}
              />
            </form>
          </Grid>
          <Grid item xs={6} sx={gridStyle}>
            <Pagination length={length} setPage={setPage} page={page} setLimit={setLimit} limit={limit} />
          </Grid>

          <Grid item>
            <Filter filter={filter} title={'Tags'} names={names} selectionChangeHandler={selectionChangeTagsFilter} />
          </Grid>
          <Grid item>
            <Filter filter={group} title={'Groups'} names={groups} selectionChangeHandler={selectionChangeGroupsFilter} />
          </Grid>
        </Grid>

        <Grid container justifyContent={'center'}>
          <Grid item xs={12}>
            <AlertsTable isLoading={response.isLoading} response={response.data} containerStyle={{height: '82.5vh'}} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Alertes;
