import axios from 'axios'
import { AUTH_BASE_URL } from '../../config/contants'

export const useAuthenticationApi = () => {
    const doRefreshToken = (refreshToken: string) => {
        const body = {
            refreshToken,
        }
        // ?!! (Samuel Gallet) Here we are not using axios instance in order
        // To not go into common interceptor
        return axios
            .post(`${AUTH_BASE_URL}/v1/refresh`, body)
            .then((response) => response.data)
    }

    const signIn = (email: string, password: string) => {
        const body = {
            email: email,
            password: password,
        }
        return axios
            .post(AUTH_BASE_URL + '/v1/login', body)
            .then((response) => response.data)
    }
    return { doRefreshToken, signIn }
}
