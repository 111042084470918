import React from 'react';
import {useAuthTokens} from './hooks/authentication/useAuthToken';
import {Route, Routes} from 'react-router-dom';
import Home from './scenes/home/Home';
import Login from './scenes/authentication/Login';
import AuthenticatedRoute from './scenes/authentication/AuthenticatedRoute';
import Alerts from './scenes/alerts/Alerts';
import AlertsId from './scenes/alerts/alertsId/AlertsId';

import LeftBar from './scenes/ui/LeftBar';
import Background from './scenes/ui/Background';
import {Navigate} from 'react-router-dom';
import {routes} from './config/routes';
import Loader from './scenes/ui/Loader';
import Parc from './scenes/parc/Parc';
import Mtbf from './scenes/mtbf/Mtbf';
import Battery from './scenes/battery/Battery';

function Main() {
  const {isLoading} = useAuthTokens();
  return (
    <Background>
      {isLoading ? (
        <Loader />
      ) : (
        <Routes>
          <Route
            path={routes.login}
            element={
              <AuthenticatedRoute requiredAuth={false}>
                <Login />
              </AuthenticatedRoute>
            }
          />
          <Route element={<LeftBar />}>
            <Route
              path={routes.alerts}
              element={
                <AuthenticatedRoute requiredAuth={true}>
                  <Alerts />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={routes.alertsId}
              element={
                <AuthenticatedRoute requiredAuth={true}>
                  <AlertsId />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={routes.home}
              element={
                <AuthenticatedRoute requiredAuth={true}>
                  <Home />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={routes.parc}
              element={
                <AuthenticatedRoute requiredAuth={true}>
                  <Parc />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={routes.mtbf}
              element={
                <AuthenticatedRoute requiredAuth={true}>
                  <Mtbf />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={routes.battery}
              element={
                <AuthenticatedRoute requiredAuth={true}>
                  <Battery />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="*"
              element={
                <AuthenticatedRoute requiredAuth={true}>
                  <Navigate replace to={routes.home} />
                </AuthenticatedRoute>
              }
            />
          </Route>
        </Routes>
      )}
    </Background>
  );
}

export default Main;
