import React, {CSSProperties} from 'react';
import {useTheme} from '@mui/material';

type Props = {
    children?: React.ReactNode;
    style?: CSSProperties;
};
const Card = ({children, style}: Props) => {
    const theme = useTheme();
    const cardStyle = {
        flexDirection: 'column' as 'column',
        backgroundColor: theme.palette.primary.main,
    };
    return <div style={{...style, ...cardStyle}}>{children}</div>;
};

export default Card;
