import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import {TableContainer} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import CustomTableRow from '../ui/CustomTableRow';
import {Battery} from '../../entity/Battery';
import formatPercentage from '../../utils/formatPercentage';

type Props = {
  data: Battery;
};
const BatteryTable = ({data}: Props) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <CustomTableRow>
            <th>IMEI</th>
            <th>Status</th>
            <th>Battery</th>
          </CustomTableRow>
        </TableHead>
        <TableBody style={{overflow: 'auto'}}>
          {data.data.map((row, index) => (
            <CustomTableRow key={index}>
              <td>
                <a style={{color: 'inherit'}} target="_blank" rel="noreferrer" href={`https://dashboard.artifeel.com/?deviceImei=${row.imei}`}>
                  {row.imei}
                </a>
              </td>
              <td>{row.batteryLevels.status === 'noBattery' ? 'BatteryNotFound' : row.batteryLevels.status.toLocaleUpperCase()}</td>
              <td>{formatPercentage(row.batteryLevels.percentage)}</td>
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BatteryTable;
