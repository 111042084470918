import {Checkin} from '../../entity/Checkin';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CustomTableRow from '../ui/CustomTableRow';

type Props = {
  data: Checkin;
};

const Telesurveillance = ({data}: Props) => {
  return (
    <Table>
      <TableBody style={{overflow: 'auto'}}>
        {data.telesurveillance.map((item, index) =>
          index <= 10 ? (
            <CustomTableRow key={index} style={{textAlign: 'start', height: '5vh'}}>
              <td style={{paddingLeft: '10%'}}>{`${item._id}: `}</td>
              <td>{` ${item.count}`}</td>
            </CustomTableRow>
          ) : null,
        )}
      </TableBody>
    </Table>
  );
};

export default Telesurveillance;
