import {Grid} from '@mui/material';
import {useTheme} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {useGetHealthServices} from '../../hooks/api/useGetHealthServices';
import CardData from '../ui/CardData';
import Loader from '../ui/Loader';

const HealthServices = () => {
  const theme = useTheme();

  const req = useGetHealthServices();

  return (
    <Grid item container xs={11.5} marginTop={5}>
      <CardData style={{height: 'auto'}}>
        {req.data ? (
          <Grid item container direction={'column'} spacing={1.5} fontWeight={'bold'} paddingLeft={'1vw'}>
            {req.data.data.map((item, index) => (
              <Grid item key={index} alignItems={'center'} display={'flex'}>
                <CircleIcon style={{color: item.status === 200 ? '#16db65' : theme.palette.error.main}} />
                <span style={{color: theme.palette.primary.light, fontSize: '1vw'}}> {item.url}</span>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Loader />
        )}
      </CardData>
    </Grid>
  );
};

export default HealthServices;
