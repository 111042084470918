import React from 'react';
import {Grid} from '@mui/material';
import Checkin from './Checkin';
import HealthServices from './HealthServices';

const Home: React.FC = () => {
  return (
    <Grid container item xs={12} justifyContent={'center'}>
      <Checkin />
      <HealthServices />
    </Grid>
  );
};
export default Home;
