import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';

import en from './translation/en.json';
import fr from './translation/fr.json';

i18n.use(initReactI18next)
    .use(languageDetector)
    .init({
        resources: {
            en,
            fr,
        },
        //lng: 'fr',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
