import {useLocation, useNavigate} from 'react-router-dom';
import {routes} from '../../config/routes';
import {useAuthTokens} from '../../hooks/authentication/useAuthToken';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import {IconButton} from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import {useTheme} from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import BatteryChargingFullOutlinedIcon from '@mui/icons-material/BatteryChargingFullOutlined';

import {Grid} from '@mui/material';
import {Box} from '@mui/material';

import {Outlet} from 'react-router-dom';
import {useEffect, useState} from 'react';

const LeftBar = () => {
  const {deleteTokens} = useAuthTokens();
  const navigate = useNavigate();
  const theme = useTheme();

  const logout = () => {
    deleteTokens();
    navigate(routes.login);
  };

  const iconStyle = {
    color: theme.palette.secondary.main,
    height: '90%',
    width: '90%',
  };
  const [selected, setSelected] = useState('home');

  const location = useLocation();
  useEffect(() => {
    const paths = location.pathname.split('/');
    if (paths.length <= 1 || paths[1].length === 0) {
      setSelected('home');
      return;
    }
    setSelected(paths[1]);
  }, [location.pathname]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={0.75}>
        <Box sx={{backgroundColor: theme.palette.primary.main, height: '100%'}}>
          <IconButton
            onClick={() => {
              navigate(routes.home);
            }}
            sx={{border: selected === 'home' ? '4px solid ' + theme.palette.primary.light : null, borderRadius: 5}}>
            <HomeIcon style={iconStyle}></HomeIcon>
          </IconButton>
          <IconButton
            onClick={() => {
              navigate(routes.alerts);
            }}
            sx={{border: selected === 'alerts' ? '4px solid ' + theme.palette.primary.light : null, borderRadius: 5}}>
            <NewReleasesIcon style={iconStyle}></NewReleasesIcon>
          </IconButton>
          <IconButton
            onClick={() => {
              navigate(routes.mtbf);
            }}
            sx={{border: selected === 'mtbf' ? '4px solid ' + theme.palette.primary.light : null, borderRadius: 5}}>
            <RunningWithErrorsIcon style={iconStyle}></RunningWithErrorsIcon>
          </IconButton>
          <IconButton
            onClick={() => {
              navigate(routes.battery);
            }}
            sx={{
              border: selected === 'battery' ? '4px solid ' + theme.palette.primary.light : null,
              borderRadius: 5,
            }}>
            <BatteryChargingFullOutlinedIcon style={iconStyle}></BatteryChargingFullOutlinedIcon>
          </IconButton>
          <IconButton
            onClick={() => {
              navigate(routes.parc);
            }}
            sx={{border: selected === 'parc' ? '4px solid ' + theme.palette.primary.light : null, borderRadius: 5}}>
            <InventoryIcon style={iconStyle}></InventoryIcon>
          </IconButton>
          <IconButton onClick={() => logout()}>
            <LogoutIcon style={iconStyle}></LogoutIcon>
          </IconButton>
        </Box>
      </Grid>
      <Grid container item xs={11.25} paddingRight={2}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default LeftBar;
