import {PieChart, Pie, Cell, ResponsiveContainer, Legend} from 'recharts';

type Props = {
  data: {
    key: string;
    'active-checkin': number;
  }[];
};

const COLORS = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];

const FirmwareCharts = ({data}: Props) => {
  return (
    <ResponsiveContainer width="100%" height="90%">
      <PieChart>
        <Pie data={data} cx="50%" cy="40%" label outerRadius={120} dataKey="active-checkin">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          payload={data.map((item, index) => ({
            id: item.key,
            type: 'square',
            value: `${item.key}`,
            color: COLORS[index % COLORS.length],
          }))}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default FirmwareCharts;
