import React, {CSSProperties} from 'react';
import {useTheme} from '@mui/material';

type Props = {
  color?: string;
  children?: React.ReactNode;
  style?: CSSProperties;
};

function Background({children, style}: Props) {
  const theme = useTheme();

  const backgroundStyle = {
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    overflowY: 'scroll' as 'scroll',
  };

  return <div style={{...backgroundStyle, ...style}}>{children}</div>;
}

export default Background;
