import {Box, Grid} from '@mui/material';
import {useTheme} from '@mui/material';
import ButtonCustom from '../ui/Button';
import {usePutMtbf} from '../../hooks/api/usePutMtbf';
import {useGetMtbf} from '../../hooks/api/useGetMtbf';
import MtbfContainer from './MtbfContainer';
import {useState} from 'react';
import dayjs from 'dayjs';
require('dayjs/locale/fr');
dayjs.locale('fr');

const Mtbf = () => {
  const theme = useTheme();

  const {updateMtbf: updateMtbfDaily} = usePutMtbf();
  const {updateMtbf: updateMtbfWeekly} = usePutMtbf();
  const {updateMtbf: updateMtbfMonthly} = usePutMtbf();

  let mtbfDaily = useGetMtbf('daily', 6, dayjs(Date.now()).endOf('day').utc().unix() * 1000);
  let mtbfWeekly = useGetMtbf('weekly', 5, dayjs(Date.now()).endOf('week').utc().unix() * 1000);
  let mtbfMonthly = useGetMtbf('monthly', 4, dayjs(Date.now()).endOf('month').utc().unix() * 1000);

  const [isLoadingDailyUpdate, setLoadingDailyUpdate] = useState(false);
  const [isLoadingWeeklyUpdate, setLoadingWeeklyUpdate] = useState(false);
  const [isLoadingMonthlyUpdate, setLoadingMonthlyUpdate] = useState(false);

  const handleDailyMtbf = async () => {
    const promises = [];

    setLoadingDailyUpdate(true);

    // Update last 14 days
    for (let i = 0; i < 14; i++) {
      promises.push(
        updateMtbfDaily(
          'daily',
          dayjs(Date.now()).subtract(i, 'day').endOf('day').utc().unix() * 1000,
          dayjs(Date.now()).subtract(i, 'day').startOf('day').utc().unix() * 1000,
          6,
          dayjs(Date.now()).endOf('day').utc().unix() * 1000,
        ),
      );
    }
    await Promise.all(promises);
    setLoadingDailyUpdate(false);
  };

  const handleWeeklyUpdate = async () => {
    const promises = [];

    setLoadingWeeklyUpdate(true);

    // Update last 2 weeks
    for (let i = 0; i < 2; i++) {
      promises.push(
        updateMtbfWeekly(
          'weekly',
          dayjs(Date.now()).subtract(i, 'week').endOf('week').utc().unix() * 1000,
          dayjs(Date.now()).subtract(i, 'week').startOf('week').utc().unix() * 1000,
          5,
          dayjs(Date.now()).endOf('week').utc().unix() * 1000,
        ),
      );
    }
    await Promise.all(promises);
    setLoadingWeeklyUpdate(false);
  };

  const handleMonthlyUpdate = async () => {
    const promises = [];

    setLoadingMonthlyUpdate(true);

    // Update last month
    promises.push(
      updateMtbfMonthly(
        'monthly',
        dayjs(Date.now()).endOf('month').utc().unix() * 1000,
        dayjs(Date.now()).startOf('month').utc().unix() * 1000,
        4,
        dayjs(Date.now()).endOf('month').utc().unix() * 1000,
      ),
    );

    await Promise.all(promises);
    setLoadingMonthlyUpdate(false);
  };

  const handleUpdateMtbf = () => {
    handleDailyMtbf();
    handleWeeklyUpdate();
    handleMonthlyUpdate();
  };

  return (
    <Grid container item marginTop={5}>
      <Grid item xs={12} style={{color: theme.palette.primary.light, fontStyle: 'italic'}}>
        <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
          <div>
            <ButtonCustom
              isLoading={isLoadingDailyUpdate || isLoadingWeeklyUpdate || isLoadingMonthlyUpdate ? true : false}
              text={'Update'}
              style={{backgroundColor: theme.palette.primary.main, color: theme.palette.primary.light}}
              onClick={() => handleUpdateMtbf()}
            />
          </div>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <MtbfContainer data={mtbfDaily.data} isLoading={isLoadingDailyUpdate} type={'Daily'}></MtbfContainer>
      </Grid>
      <Grid item xs={12}>
        <MtbfContainer data={mtbfWeekly.data} isLoading={isLoadingWeeklyUpdate} type={'Weekly'}></MtbfContainer>
      </Grid>
      <Grid item xs={12}>
        <MtbfContainer data={mtbfMonthly.data} isLoading={isLoadingMonthlyUpdate} type={'Monthly'}></MtbfContainer>
      </Grid>
    </Grid>
  );
};

export default Mtbf;
