import useSWR from 'swr';
import {Alerts} from '../../entity/Alerts';
import {useFetcher} from '../useFetcher';
import {ENDPOINT_URL} from '../../config/contants';

export const useGetAlertsApi = (search: string, limit: number, page: number, filter: string[], group: string[]) => {
  const {get} = useFetcher();
  const response = useSWR(ENDPOINT_URL + `/alerts?search=${search}&limit=${limit}&page=${page}&tagsFilter=${filter}&groupsFilter=${group}`, get);

  return {
    ...response,
    data: response.data === undefined ? undefined : (response.data as Alerts),
  };
};
