import {useState} from 'react';
import {useTheme} from '@mui/material';
import {useAlert} from '../../../hooks/api/useAlertIdapix';
import {useTranslation} from 'react-i18next';
import RadioButtons from '../../ui/Radiobuttons';
import {Grid} from '@mui/material';
import {DataAlertsId} from '../../../entity/Alerts';

type Props = {
  data: DataAlertsId;
};
const AlertsTags = ({data}: Props) => {
  const theme = useTheme();
  const [value, setValue] = useState(data.tag);
  const {createMetadata, updateMetadata} = useAlert();
  const {t} = useTranslation();

  const tags = [
    {value: 'alert', label: t('alerts.tags.alert')},
    {value: 'fake-alert', label: t('alerts.tags.fake-alert')},
    {value: 'test', label: t('alerts.tags.test')},
    {value: 'works', label: t('alerts.tags.works')},
    {value: 'bad-usage', label: t('alerts.tags.bad-usage')},
    {value: 'pending', label: t('alerts.tags.pending')},
  ];

  const radioStyle = {
    color: theme.palette.primary.light,
    '&.Mui-checked': {
      color: theme.palette.primary.light,
    },
    '& .MuiSvgIcon-root': {
      width: '2vw',
    },
  };

  const handleChange = async (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setValue((event.target as HTMLInputElement).value);
    const body = {imei: data.imei, alertId: data._id, alertTag: (event.target as HTMLInputElement).value};

    if (data.comment || data.tag) await updateMetadata(body, data._id);
    else await createMetadata(body, data._id);
  };

  return (
    <Grid container paddingLeft={3}>
      {tags.map((item, index) => (
        <Grid item xs={12} key={index}>
          <RadioButtons value={item.value} label={item.label} style={radioStyle} handleChange={handleChange} checked={value === item.value ? true : false} />
        </Grid>
      ))}
    </Grid>
  );
};

export default AlertsTags;
