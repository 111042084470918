import {Grid} from '@mui/material';
import CardData from '../../ui/CardData';
import {useTheme} from '@mui/material';
import {LineChart, Line, XAxis, YAxis, Legend, ResponsiveContainer} from 'recharts';
import dayjs from 'dayjs';

type Props = {
  timestamp: string;
  data: {}[];
  title: string;
};
const Charts = ({timestamp, data, title}: Props) => {
  const theme = useTheme();

  let date = dayjs(timestamp).utc().local().format('DD-M-YYYY HH:mm:ss');

  return (
    <Grid item xs={12} height={'40vh'} marginTop={'3vh'}>
      <CardData>
        <ResponsiveContainer height={'80%'} width={'90%'}>
          <LineChart data={data}>
            <XAxis stroke={theme.palette.primary.light} />
            <YAxis stroke={theme.palette.primary.light} />
            <Line type="monotone" dataKey="x" stroke={theme.palette.info.main} dot={false} />
            <Line type="monotone" dataKey="y" stroke={theme.palette.info.dark} dot={false} />
            <Line type="monotone" dataKey="z" stroke={theme.palette.info.light} dot={false} />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
        <span style={{height: '10%', marginTop: '1vh'}}>
          {title}: {date}
        </span>
      </CardData>
    </Grid>
  );
};

export default Charts;
