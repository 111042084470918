import {Checkin} from '../../entity/Checkin';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import CustomTableRow from '../ui/CustomTableRow';
import {useTranslation} from 'react-i18next';
import {TableContainer} from '@mui/material';
import {useState} from 'react';

type Props = {
  data: Checkin;
};
const OrganizationTable = ({data}: Props) => {
  const {t} = useTranslation();

  const [sortState, setSortState] = useState<string>();

  const sortedData = () => {
    if (sortState === 'active') {
      data.customersTopKpis.sort(function (a, b) {
        return b.nbOfActiveDevices - a.nbOfActiveDevices;
      });
    } else if (sortState === 'inStock') {
      data.customersTopKpis.sort(function (a, b) {
        return b.count - a.count;
      });
    } else if (sortState === '_id') {
      data.customersTopKpis.sort(function (a, b) {
        return a._id.customer.localeCompare(b._id.customer);
      });
    }
    return data.customersTopKpis;
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <CustomTableRow>
            <th>{t('#')}</th>
            <th onClick={() => setSortState('_id')}>{t('parc.organizations')}</th>
            <th onClick={() => setSortState('active')}>{t('parc.active_checkin')}</th>
            <th onClick={() => setSortState('inStock')}>{t('parc.inStock_checkin')}</th>
          </CustomTableRow>
        </TableHead>
        <TableBody style={{overflow: 'auto'}}>
          {sortedData().map((item, index) => (
            <CustomTableRow key={index}>
              <td>{index}.</td>
              <td>{item._id.customer}</td>
              <td>{item.nbOfActiveDevices}</td>
              <td>{item.count}</td>
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrganizationTable;
