import {Checkin} from '../../entity/Checkin';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CustomTableRow from '../ui/CustomTableRow';

type Props = {
  data: Checkin;
};

const CheckinFirmwareInfos = ({data}: Props) => {
  return (
    <Table>
      <TableBody style={{overflow: 'auto'}}>
        {data.activeCheckInFwVersion.map((item, index) => (
          <CustomTableRow key={index} style={{textAlign: 'start', height: '5vh'}}>
            <td style={{paddingLeft: '10%'}}>{`${item._id ? item._id : 'UNKNOWN'}: `}</td>
            <td>{`${item.count}`}</td>
          </CustomTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CheckinFirmwareInfos;
