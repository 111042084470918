import useSWR from 'swr';
import {Checkin} from '../../entity/Checkin';
import {useFetcher} from '../useFetcher';
import {ENDPOINT_URL} from '../../config/contants';

export const useGetGlobalKpi = () => {
  const {get} = useFetcher();
  const response = useSWR(ENDPOINT_URL + '/global-kpi', get);

  return {
    ...response,
    data: response.data === undefined ? undefined : (response.data as Checkin),
  };
};
