import {useTheme} from '@mui/material';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
import {GlobalHistory} from '../../entity/GlobalHistory';
import {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import Loader from '../ui/Loader';

type Props = {
  data: GlobalHistory[] | undefined;
};

const MtbfCharts = ({data}: Props) => {
  const theme = useTheme();
  const [isLoading, setLoading] = useState(true);
  const [newData, setnewData] = useState<GlobalHistory[]>();
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    if (!data) return;

    let copiedArray = JSON.parse(JSON.stringify(data));

    for (const mtbf of copiedArray) {
      mtbf.stop = dayjs(mtbf.stop).format('DD-MM-YYYY');
      if (mtbf.mtbf === -1) mtbf.mtbf = 0;
      else mtbf.mtbf = Math.round(mtbf.mtbf * 100) / 100;
    }

    let maxValue = Math.max.apply(
      null,
      copiedArray.map(function (o: GlobalHistory) {
        return o.mtbf;
      }),
    );
    copiedArray = copiedArray.reverse();
    setMaxValue(maxValue * 2);
    setnewData(copiedArray);
    setLoading(false);
  }, [data]);

  return (
    <ResponsiveContainer height={'95%'} width={'95%'}>
      {!isLoading ? (
        <BarChart
          data={newData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          barSize={40}>
          <CartesianGrid strokeDasharray="3 3" opacity={0.5} />
          <XAxis stroke={theme.palette.primary.light} dataKey="stop" dy={10} />
          <YAxis type="number" domain={[0, Math.round(maxValue / 100) * 100]} stroke={theme.palette.primary.light} dx={-10} />
          <Bar dataKey="mtbf" fill={theme.palette.primary.light} />
          <Tooltip cursor={{fill: 'transparent'}} itemStyle={{color: theme.palette.primary.main}} />
        </BarChart>
      ) : (
        <Loader />
      )}
    </ResponsiveContainer>
  );
};

export default MtbfCharts;
