import React from 'react';
import {AuthTokenProvider} from './hooks/authentication/useAuthToken';
import {ThemeProvider} from '@mui/material/styles';
import {theme} from './scenes/ui/Theme';

import Main from './Main';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

function App() {
    return (
        <AuthTokenProvider>
            <ThemeProvider theme={theme}>
                <Main />
            </ThemeProvider>
        </AuthTokenProvider>
    );
}

export default App;
