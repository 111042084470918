import CardData from '../ui/CardData';
import {Grid} from '@mui/material';
import {useTheme} from '@mui/material';
import {useGetGlobalKpi} from '../../hooks/api/useGetGlobalKpi';
import Loader from '../ui/Loader';
import {useTranslation} from 'react-i18next';
import CheckinTopClients from './CheckinTopClients';
import CheckinFirmwareInfos from './CheckinFirmwareInfos';
import Telesurveillance from './Telesurveillance';
import AlertsTable from '../alerts/AlertsTable';
import {useGetAlertsApi} from '../../hooks/api/useGetAlertsApi';
import {useEffect, useState} from 'react';
import TopTransmissionTime from './TopTransmissionTime';
import {TopTransmissionTimeEntity} from '../../entity/Checkin';
import CheckinCharts from './CheckinCharts';

const Checkin = () => {
  const theme = useTheme();
  const {t} = useTranslation();

  const response = useGetGlobalKpi();

  const cardContainer = {
    display: 'flex',
    alignItems: 'center',
  };

  const titleStyle = {
    color: theme.palette.primary.light,
    fontSize: '1vw',
  };

  const recentsAlerts = useGetAlertsApi('', 5, 0, [], []);

  const [topDescCheckinTransmissionTime, setTopDescCheckinTransmissionTime] = useState<TopTransmissionTimeEntity[]>([]);
  const [topAscCheckinTransmissionTime, setTopAscCheckinTransmissionTime] = useState<TopTransmissionTimeEntity[]>([]);

  useEffect(() => {
    if (!response.data) return;

    const deviceAverageTransmissionTimeSorted =
      response.data.imeisTransmissionTime?.metadatas.sort((a, b) => {
        return b.deviceAverageTransmissionTime - a.deviceAverageTransmissionTime;
      }) ?? [];
    const desc = deviceAverageTransmissionTimeSorted.filter((obj) => obj.alertsNumber >= 2).slice(0, 10);
    const asc = deviceAverageTransmissionTimeSorted
      .reverse()
      .filter((obj) => obj.alertsNumber >= 2)
      .slice(0, 10);

    setTopAscCheckinTransmissionTime(asc);
    setTopDescCheckinTransmissionTime(desc);
  }, [response.data]);

  return (
    <Grid container item justifyContent={'center'} marginTop={5}>
      <Grid item container xs={11.5} height={'55vh'} spacing={2}>
        <Grid item container xs={3}>
          <Grid item xs={12} style={cardContainer} flexDirection={'column'}>
            <h1 style={titleStyle}>{t('home.checkin_connected')}</h1>
            <CardData style={{fontSize: '2vw'}}>{response.data ? response.data.activeCheckin : <Loader />}</CardData>
            <h1 style={titleStyle}>{t('home.checkin_sent')}</h1>
            <CardData style={{fontSize: '2vw'}}>{response.data ? 'NODATA' : <Loader />}</CardData>
          </Grid>
        </Grid>
        <Grid item container xs={9}>
          <Grid item style={cardContainer} flexDirection={'column'} xs={12}>
            <h1 style={titleStyle}>{t('home.active_checkin_chart')}</h1>
            <CardData>{response.data ? <CheckinCharts data={response.data} /> : <Loader />}</CardData>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={11.5}>
        <Grid item xs={12} style={cardContainer} flexDirection={'column'} marginTop={5}>
          <h1 style={titleStyle}>{t('home.recent_alerts')}</h1>
          <CardData>{recentsAlerts.data ? <AlertsTable isLoading={false} response={recentsAlerts.data} /> : <Loader />}</CardData>
        </Grid>
      </Grid>

      <Grid item container xs={11.5} marginTop={5}>
        <Grid container spacing={2}>
          <Grid item xs={3} style={cardContainer} flexDirection={'column'}>
            <h1 style={titleStyle}>{t('home.top_10_clients')}</h1>
            <CardData>{response.data ? <CheckinTopClients data={response.data} /> : <Loader />}</CardData>
          </Grid>

          <Grid item xs={3} style={cardContainer} flexDirection={'column'}>
            <h1 style={titleStyle}>{t('home.checkin_connected_firmware_version')}</h1>
            <CardData style={{justifyContent: 'start'}}> {response.data ? <CheckinFirmwareInfos data={response.data} /> : <Loader />}</CardData>
          </Grid>

          <Grid item xs={3} style={cardContainer} flexDirection={'column'}>
            <h1 style={titleStyle}>{t('home.monitoring')}</h1>
            <CardData style={{justifyContent: 'start'}}>{response.data ? <Telesurveillance data={response.data} /> : <Loader />}</CardData>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={11.5} marginTop={5}>
        <Grid container spacing={2}>
          <Grid item xs={6} style={cardContainer} flexDirection={'column'}>
            <h1 style={titleStyle}>{t('home.top_10_checkin_transmission_time_asc')}</h1>
            <CardData>{response.data && topAscCheckinTransmissionTime ? <TopTransmissionTime data={topAscCheckinTransmissionTime} /> : <Loader />}</CardData>
          </Grid>
          <Grid item xs={6} style={cardContainer} flexDirection={'column'}>
            <h1 style={titleStyle}>{t('home.top_10_checkin_transmission_time_desc')}</h1>
            <CardData>{response.data && topDescCheckinTransmissionTime ? <TopTransmissionTime data={topDescCheckinTransmissionTime} /> : <Loader />}</CardData>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Checkin;
