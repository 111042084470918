import {Grid} from '@mui/material';
import {useGetAlertsIdApi} from '../../../hooks/api/useGetAlertsIdApi';
import {useParams} from 'react-router-dom';
import {useTheme} from '@mui/material';
import {useEffect, useState} from 'react';
import Charts from './Charts';
import InfoCapteur from './InfoCapteur';
import InfoClient from './InfoClient';
import InfoTs from './InfoTs';
import InfoAlert from './InfoAlert';
import AlertsTags from './AlertsTags';
import CardData from '../../ui/CardData';
import Loader from '../../ui/Loader';
import Comment from './Comment';

const cardContainer = {
  display: 'flex',
  alignItems: 'center',
};
const AlertsId = () => {
  const params = useParams();
  const id = params.id ? params.id : '';
  const theme = useTheme();

  const [data, setData] = useState([{ts: '', acceleration: [{}], gyroscope: [{}]}]);
  const [isLoading, setLoading] = useState(true);

  const titleStyle = {fontSize: '2vw', color: theme.palette.primary.light, marginTop: '3vh'};

  const response = useGetAlertsIdApi(id);

  useEffect(() => {
    if (!response.data || !response.data.data) return;

    const fifo = response.data.data.fifo;
    let global = [];

    for (let size = 0; size !== fifo.length; size++) {
      let globalArrayAcceleration = [];
      for (let i = 0; i !== fifo[size].datas.accXSerie.length; i++) {
        let dict = {
          t: i,
          x: fifo[size].datas.accXSerie[i],
          y: fifo[size].datas.accYSerie[i],
          z: fifo[size].datas.accZSerie[i],
        };
        globalArrayAcceleration.push(dict);
      }

      let globalArrayGyroscope = [];
      for (let i = 0; i !== fifo[size].datas.gyrXSerie.length; i++) {
        let dict = {
          t: i,
          x: fifo[size].datas.gyrXSerie[i],
          y: fifo[size].datas.gyrYSerie[i],
          z: fifo[size].datas.gyrZSerie[i],
        };
        globalArrayGyroscope.push(dict);
      }

      const newData = {ts: fifo[size].ts, acceleration: globalArrayAcceleration, gyroscope: globalArrayGyroscope};
      global.push(newData);
    }
    setData(global);
    setLoading(false);
  }, [response.data]);

  if (response.error) return <div>failed to load</div>;

  return (
    <Grid container item xs={12}>
      <Grid container marginTop={5} justifyContent={'center'} spacing={1}>
        <Grid item xs={2.5}>
          <CardData>{response.data ? <AlertsTags data={response.data.data} /> : <Loader />}</CardData>
        </Grid>
        <Grid item xs={5} flexDirection={'column'}>
          <CardData style={{justifyContent: 'start'}}>{response.data ? <InfoAlert data={response.data.data} /> : <Loader />}</CardData>
        </Grid>
        <Grid item xs={4}>
          <CardData>{response.data ? <Comment data={response.data.data} /> : <Loader />}</CardData>
        </Grid>
      </Grid>

      <Grid container justifyContent={'center'} spacing={1} marginTop={5}>
        <Grid item xs={5.75} style={cardContainer} flexDirection={'column'}>
          <h1 style={titleStyle}>Informations Capteur</h1>
          <CardData>{response.data ? <InfoCapteur data={response.data.data} /> : <Loader />}</CardData>
        </Grid>
        <Grid item xs={5.75} style={cardContainer} flexDirection={'column'}>
          <h1 style={titleStyle}>Informations Client</h1>
          <CardData>{response.data ? <InfoClient data={response.data.data} /> : <Loader />}</CardData>
        </Grid>
      </Grid>

      <Grid container marginTop={5} justifyContent={'center'}>
        <Grid item xs={4.25} style={cardContainer} flexDirection={'column'}>
          <h1 style={titleStyle}>Ts Parameters</h1>
          {response.data ? (
            response.data.data.tsParameters ? (
              <CardData style={{justifyContent: 'start', height: 'auto'}}>
                <InfoTs data={response.data.data} />
              </CardData>
            ) : (
              <h1 style={titleStyle}>NoData</h1>
            )
          ) : (
            <CardData>
              <Loader />
            </CardData>
          )}
        </Grid>

        <Grid item xs={7} style={cardContainer} flexDirection={'column'}>
          <h1 style={titleStyle}>Graphiques</h1>

          {response.data ? (
            response.data.data.fifo.length > 0 && isLoading === false ? (
              <Grid container direction={'column'}>
                {data.map((item, index) => (
                  <div key={index}>
                    <Grid container justifyContent={'end'}>
                      <Grid item xs={11}>
                        <h1 style={titleStyle}>Fifo: {index}</h1>
                        <Charts timestamp={item.ts} data={item.acceleration} title={'Acceleration'} />
                        <Charts timestamp={item.ts} data={item.gyroscope} title={'Gyroscope'} />
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </Grid>
            ) : (
              <h1 style={titleStyle}>NoData</h1>
            )
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AlertsId;
