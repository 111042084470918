import {useFetcher} from '../useFetcher';
import {ENDPOINT_URL} from '../../config/contants';
import {useSWRConfig} from 'swr';

export const useAlert = () => {
  const {put, post} = useFetcher();
  const {mutate} = useSWRConfig();

  let url = ENDPOINT_URL + '/alerts/';
  const updateMetadata = async (body: {}, alertId: string) => {
    try {
      await put(url + alertId + '/metadata', body);
      mutate(url + alertId);
    } catch (err) {}
  };
  const createMetadata = async (body: {}, alertId: string) => {
    try {
      await post(url + alertId + '/metadata', body);
      mutate(url + alertId);
    } catch (err) {}
  };

  return {
    updateMetadata,
    createMetadata,
  };
};
